<template>
  <div class="zimore-main-container">
    <div id="zimore-info" class="zimore-info-container" v-for="(item,index) in showZhaoshang" :key="index">
      <img class="zimore-info-img" :src="item.url"/>
      <div class="zimore-info-txt-container">
        <div class="txt-title">{{item.title}}</div>
        <div class="txt-desc">{{item.content}}</div>
        <div class="txt-fanwei" style="bottom:2rem">招募范围:{{item.scope}}</div>
        <div class="txt-fanwei" style="bottom:1rem">联系方式:{{item.phone}}</div>
        <div class="txt-fanwei" style="bottom:0">电子邮箱:{{item.email}}</div>
        <div class="join-us">
          <div v-b-modal.joinus>加入我们</div>
        </div>
      </div>
    </div>
    <b-modal
      id="joinus"
      title="招商信息"
      :input-type="inputType"
      :hide-header-close="true"
      :hide-footer="true">
      <b-form-select id="type" v-model="inputType" :options="selectType">
      <template v-solt:first>
        <option @input="checkInput" :value="null" disabled style="color:#495057">选择类型</option>
      </template>
      </b-form-select>
      <b-input @input="checkInput" id="name" style="margin-top:10px" placeholder="请输入联系人姓名或公司名称"></b-input>
      <b-input @input="inputValue" maxlength="11" type='tel' id="phone" style="margin-top:10px" placeholder="请输入联系方式"></b-input>
      <b-input @input="checkInput" id="area" style="margin-top:10px" placeholder="请输入地区"></b-input>
      <div style="display:flex;margin-top:10px">
          <b-input @input="checkInput" id="checkcode" placeholder="请输入验证码"></b-input>
          <b-button id="codeCheck" :disabled="codeCheck" @click="sendCode" style="width:200px;height:1.78rem">获取验证码</b-button>
      </div>
      <b-button @click="submitInfo" :disabled="subCheck" ref="hidemodal" style="margin-top:30px;width:100%">立即提交</b-button>
    </b-modal>
    <pager :total="page" @transfer="setInfos" :type=1></pager>
  </div>
</template>

<script>
import UtilsPath from '../../utils/UtilsPath'
import axios from 'axios'
import pager from '../Pager'
export default {
  data () {
    return {
      zhaoshangInfos: [],
      showZhaoshang: [],
      page: 0,
      inputType: null,
      selectType: ['1', '2', '3'],
      codeCheck: true,
      subCheck: true
    }
  },
  computed: {
    // rows () {
    //   return this.$store.getters.getZhaoshangItems.length
    // }
  },
  methods: {
    setInfos (item) {
      this.$data.showZhaoshang = item
    },
    async submitInfo () {
      let tempType = document.getElementById('type').value
      let tempName = document.getElementById('name').value
      let tempPhone = document.getElementById('phone').value
      let tempArea = document.getElementById('area').value
      let tempCheckCode = document.getElementById('checkcode').value
      let tempData = {
        typeName: tempType,
        linkman: tempName,
        address: tempArea,
        phone: tempPhone,
        code: tempCheckCode
      }
      let that = this
      await axios.post(UtilsPath.submitInfo, tempData).then(function (response) {
        if (response.data === 'success') {
          alert('提交成功！')
          that.$bvModal.hide('joinUs')
        } else {
          alert('验证码错误，请核实验证码信息！')
        }
      })
    },
    async sendCode () {
      let tempPhone = document.getElementById('phone').value
      if (tempPhone === null) {
        alert('请输入手机号')
      } else if (tempPhone.length !== 11) {
        alert('请输入正确的手机号')
      } else {
        let tempData = await axios.get(UtilsPath.getCheckCode + '/' + tempPhone)
        if (tempData.status === 200) {
          alert('发送成功')
        }
      }
    },
    checkInput () {
      let tempType = document.getElementById('type').value
      let tempName = document.getElementById('name').value
      let tempPhone = document.getElementById('phone').value
      let tempArea = document.getElementById('area').value
      let tempCheckCode = document.getElementById('checkcode').value
      if (tempType !== '' && tempName !== '' && tempPhone !== '' && tempArea !== '' && tempCheckCode !== '') {
        this.$data.subCheck = false
      } else {
        this.$data.subCheck = true
      }
    },
    inputValue (e) {
      if (e.length === 11) {
        this.$data.codeCheck = false
      } else {
        this.$data.codeCheck = true
      }
    }
  },
  async created () {
    let tempIndex = this.$route.params.ziIndex
    let tempData = await axios.get(UtilsPath.getBussinessDetails + '/' + tempIndex + '/' + 1 + '/' + 3)
    this.$store.commit('setShowZhaoshangItems', { showZhaoshangItems: tempData.data.records })
    this.$data.showZhaoshang = this.$store.getters.getShowZhaoshangItems
    this.$data.zhaoshangInfos = tempData.data
    this.$data.page = tempData.data.pages

    // 获取类型
    let tempBusinessContent = await axios.get(UtilsPath.getBussinessParent)
    let tempArray = []
    for (let i = 0; i < tempBusinessContent.data.length; i++) {
      tempArray[i] = tempBusinessContent.data[i].title
    }
    this.$data.selectType = tempArray
  },
  components: {
    pager
  }
}
</script>

<style scoped>
.zimore-main-container {
  display: flex;
  flex-direction: column;
}
.zimore-info-container {
  display: flex;
  width: 53.01rem;
  height: 12.85rem;
  border-bottom: 1px #dfdddd solid;
  margin-top: 2.14rem;
}
.zimore-info-img {
  width: 21.42rem;
  height: 10.71rem;
}
.zimore-info-txt-container {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-left: 2.07rem;
  height: 10.71rem;
  position: relative;
}
.txt-title{
    font-size: 1.14rem;
    color: #333333;
}
.txt-desc{
    font-size: 0.64rem;
    color: #999999;
    width: 29.71rem;
}
.txt-fanwei{
    font-size: 0.64rem;
    color: #666666;
    position: absolute;
}
.join-us{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7.14rem;
    height: 2.14rem;
    color: #fff;
    font-size: 1rem;
    background: #68d5d0;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
}
.paging-main-container {
  display: flex;
  justify-content: center;
  width: 20rem;
  height: 3rem;
  padding: 0.5rem 1rem;
}
</style>
